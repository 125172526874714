import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseApiService } from '../apis';
import { GeneralInfoTabs } from '../constants';

const CgnApiUrls = {
  suggestCompany: 'company/market-exploration/suggest-company',
  getCompanyProfile: 'improveo/company/profile-details/{firmId}',
  getCompanyDetails: 'firm/general-info/{firmId}',
  getCompanyProfileById: 'firm/profile-details/{firmId}',
};

@Injectable({
  providedIn: 'root',
})
export class GlobalNetworkService {
  private CgnFilterData$ = new BehaviorSubject<object>({});
  private CgnListLoading$ = new BehaviorSubject<{ isLoading: boolean; reload: boolean }>({ isLoading: false, reload: true });

  constructor(private baseApi: BaseApiService) {}

  /**
   * Add Suggest Company
   * @param Values Suggest company Details
   * @returns
   */
  addSuggestCompany(values): Observable<any> {
    let url = CgnApiUrls.suggestCompany;
    return this.baseApi.httpPost(url, values);
  }
  /**
   * Sets CGN filter data to subject variable
   * @param data filter details
   * @returns
   */
  setCgnFilterData(data: object) {
    this.CgnFilterData$.next(data);
  }

  /**
   * Sets CGN List loading to subject variable
   * @param  data loading data for list
   */
  setCgnListLoading(data: { isLoading: boolean; reload: boolean }) {
    this.CgnListLoading$.next(data);
  }

  /**
   * To fetch CGN List Loading status
   */
  getCgnListLoading(): Observable<any> {
    return this.CgnListLoading$.asObservable();
  }

  /**
   * Gets CGN Filter data as variable
   */
  getCgnFilterData(): Observable<any> {
    return this.CgnFilterData$.asObservable();
  }

  /**
   * Gets Company Profile Details
   * @param firmId Unique Id of  firmId
   */
  getCompanyProfileDetails(firmId: string, isConsultingPanel: boolean) {
    let url = isConsultingPanel ? CgnApiUrls.getCompanyProfileById.replace('{firmId}', firmId) : CgnApiUrls.getCompanyProfile.replace('{firmId}', firmId)
    return this.baseApi.httpGet(url);
  }

  /**
   * Gets Company Details for respective tab
   * @param firmId Unique Id of  firmId
   * @param type tab name
   */
  getCompanyTabDetails(firmId: string, isConsultingPanel: boolean , type?: GeneralInfoTabs, paginationQuery?) {
    let url = CgnApiUrls.getCompanyDetails.replace('{firmId}', firmId);
    const params = new URLSearchParams();
    if (type) params.append('type', type);
    if (paginationQuery) {
      params.append('page', paginationQuery?.page);
      params.append('limit', paginationQuery?.limit);
    }
    if(isConsultingPanel) params.append('consultingFirmId', 'true')
    return this.baseApi.httpGet(`${url}?${params.toString()}`);
  }
}
