import { Injectable } from '@angular/core';
import { UploadFileDetails } from '../interfaces/common-interfaces';
import { BaseApiService } from './base.api';
import { v4 as uuidv4 } from 'uuid';
import { get } from 'lodash-es';

const uploaderApiUrls = {
  manage: 'files/manage',
};

@Injectable({
  providedIn: 'root',
})
export class UploaderService {
  constructor(private baseApi: BaseApiService) {}

  /**
   * Gets a new pre-signed URL for viewing a file stored in bucket (used for private ACL).
   * @param fileDetails Details of the file to view
   * @returns Object containing pre-signed URL and expiry time
   */
  getFileViewUrl(fileDetails: UploadFileDetails) {
    return this.baseApi.httpPut(uploaderApiUrls.manage, fileDetails);
  }

  /**
   * Gets new pre-signed URLs for upload and preview of files stored in bucket (Private/Public-Read ACLs).
   * @param fileDetails Details of the file to upload
   * @returns Object containing pre-signed URL for upload and public URL (if public-read ACL) and expiry time
   */
  getNewUploadUrl(fileDetails: UploadFileDetails) {
    return this.baseApi.httpPost(uploaderApiUrls.manage, fileDetails);
  }

  /**
   * Uploads given data to an already generated pre-signed URL.
   * @param preSignedUrl Pre-signed URL already obtained from back-end service.
   * @param binaryData The data in binary format to be uploaded.
   * @param mimeType The MIME type of the data to be uploaded.
   * @returns
   */
  uploadToPresignedUrl(preSignedUrl: string, binaryData: unknown, mimeType: string) {
    return this.baseApi.httpPut(preSignedUrl, binaryData, { headers: { 'Content-Type': mimeType } });
  }

  /**
   * Deletes a file of specified type from the bucket.
   * @param fileDetails Details of the file to delete
   * @returns Delete Response
   */
  deleteFile(fileDetails: UploadFileDetails) {
    return this.baseApi.httpDelete(uploaderApiUrls.manage, fileDetails);
  }

  /**
   * Gets presigned URL and uploads data to the presigned URL
   * @param fileData File object
   * @param uploadPath Path in which file should be uploaded eg: path/subpath
   * @returns 
   */
  async uploadToS3(fileData: File, uploadPath = ''){
    const fileType = fileData.name.substring(fileData.name.lastIndexOf('.') + 1, fileData.name.length);
    const fileName = uuidv4() + '.' + fileType;
    const fileDetails = {
      mimeType: fileData.type,
      fileKey: fileName,
      folderPath: undefined
    };
    if(uploadPath){
      fileDetails.folderPath = uploadPath;
    }
    const urls = get(await this.getNewUploadUrl(fileDetails).toPromise(), 'data', null);
    const uploadUrl: string = urls?.uploadUrl;
    if (uploadUrl) {
      try {
        await this.uploadToPresignedUrl(uploadUrl, fileData, fileDetails.mimeType).toPromise();
        return { key: fileDetails.fileKey };
      } catch (err) {
        throw err;        
      }
    } else {
      throw "Couldn't get presigned URL";      
    }
  }
}
