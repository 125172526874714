<div class="bg-white border-radius-6 mb-12 p-x-20 p-y-12" *ngIf="isClient || (!isClient && !isSuperAdmin)">
  <div class="d-flex align-items-center justify-content-between" [ngClass]="{'h-36': isEmpty(accountDetails?.plan) && isSuperAdmin && isClient}">
    <h3 class="page-title font-Jakarta">{{ 'ContractsAdmin' | i18next }}</h3>
    <button
      color="secondary"
      class="medium"
      mat-stroked-button
      matTooltip="{{ 'Edit Contract' | i18next }}"
      matTooltipPosition="above"
      *ngIf="accountDetails?.contract?.length && isSuperAdmin"
      (click)="openAddEditDrawer(isClient ? accountTypes.Contract : accountTypes.FirmContract)"
    >
      {{'Edit' | i18next}}
    </button>
  </div>
</div>
<div class="contracts-container bg-white border-radius-6" [ngClass]="{'client': isClient , 'firm': !isClient, 'super-admin': isSuperAdmin, 'p-x-20 p-y-12': isClient || (!isClient && !isSuperAdmin) }">
<ng-container *ngIf="isLoading; else firmContainerRef">
  <div class="d-flex bg-white contracts-container border-radius-6 p-x-20 py-2 h-100">
    <div class="flex-1">
      <div class="mb-3" *ngFor="let i of [1, 2]">
        <p class="f-12 f-w-500 l-h-16 txt-grey-secondary7 skeleton-loader skeleton-title w-100 h-auto">{{ 'Type' | i18next}}</p>
        <p class="f-14 f-w-600 l-h-20 skeleton-loader skeleton-title w-100 h-auto">{{ 'Subscription' | i18next}}</p>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #firmContainerRef>
  <ng-container *ngIf="accountDetails?.contract?.length; else emptyContractContainer">
      <div>
        <p class="p-sub-heading mb-12">{{ 'Consource Global Network MSA' | i18next }}</p>
        <div class="align-items-center d-flex justify-content-between text-truncate w-420px file-card">
          <p class="file-name flex-one text-truncate">{{ accountDetails.contract[0]?.documentName }}</p>
          <button
            type="button"
            mat-stroked-button
            class="small ml-2"
            color="secondary"
            *ngIf="accountDetails.contract[0]?.documentKey"
            (click)="downloadDocument(accountDetails.contract[0]?.documentKey)"
          >
            {{'Download' | i18next}}
          </button>
        </div>
      </div>

      <div class="m-t-20">
        <p class="p-sub-heading mb-12">{{ 'Consource Marketplace Contract' | i18next }}</p>
        <div class="align-items-center d-flex justify-content-between text-truncate w-420px file-card">
          <p class="file-name flex-one text-truncate">{{ accountDetails.contract[1]?.documentName }}</p>
          <button
            type="button"
            mat-stroked-button
            class="small ml-2"
            color="secondary"
            *ngIf="accountDetails.contract[1]?.documentKey"
            (click)="downloadDocument(accountDetails.contract[1]?.documentKey)"
          >
            {{'Download' | i18next}}
          </button>
        </div>
      </div>
    </ng-container>

  <ng-template #emptyContractContainer>
    <div class="align-items-center d-flex empty-container h-100 justify-content-center">
      <div class="p-3 d-flex align-items-center justify-content-center flex-column">
        <img class="vector-image" [src]="notFoundImage" alt="no data image" />
        <p class="nodata-title font-Jakarta m-t-20">{{ 'No Records' | i18next }}</p>
        <p class="nodata-summary mw-400 m-t-12">{{ 'SORRY! There Isn\'t Any Records To Display!' | i18next }}</p>
        <button
          color="secondary"
          type="button"
          class="medium mt-2"
          mat-stroked-button
          [hidden]="!isSuperAdmin"
          (click)="openAddEditDrawer(isClient ? accountTypes.Contract : accountTypes.FirmContract)"
        >
          {{'Add Contract' | i18next}}
        </button>
      </div>
    </div>
  </ng-template>
</ng-template>
</div>

<ng-template #emptyData>
  <p class="f-14 f-w-600 l-h-20">-</p>
</ng-template>
