import { Injectable, EventEmitter } from '@angular/core';
import { BaseApiService } from './base.api';
import { BehaviorSubject, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ActionFilter, EntityDetails, MeetingFilter } from '../interfaces';

const firmRoutes = {
  dashboard: 'firm/dashboard',
  members: 'firms/members-list',
  legalEntities: 'firms/linked-legal-entities/{firmId}',
  suggestEntity: '/firms/suggest-legal-entity',
  legalEntity: 'firm/legal-entities',
  updateLegalEntity: '/firm/legal-entities/billing-info',
  requestTOEditLegalEntity: 'firm/legal-entities/change-req',
  legalEntityById: '/firms/linked-legal-entity/{entityId}',
  clientListForLegalEntity: '/firm/legal-entities/clients',
  requestData: '/firm/legal-entities/requestOnboardingData',
  onboarding: '/firm/legal-entities/onboarding',
  activateOrDeactivateLegalEntity: '/firm/legal-entity/status/{legalEntityId}',
  legalEntityDetailById: 'firm/{firmId}/legal-entity/{legalEntityId}',
  stripeCustomerPortalLink: 'firm/stripe/customer-portal',
  clientFirmPartner: 'firm/{firmId}/panel/linked-partner'
};

@Injectable({
  providedIn: 'root',
})
export class FirmService {
  public refreshLegalEntities$: Subject<boolean> = new Subject<boolean>();
  public legalEntity$: BehaviorSubject<EntityDetails> = new BehaviorSubject<EntityDetails>({} as EntityDetails);
  public meetingsFilter$: BehaviorSubject<MeetingFilter> = new BehaviorSubject<MeetingFilter>({} as MeetingFilter);
  public reloadMeetings$: EventEmitter<boolean> = new EventEmitter();
  public refreshRisk$: Subject<boolean> = new Subject<boolean>();
  public geyEntityInfo$ = new BehaviorSubject<{ onBoardingStatus: string }>({ onBoardingStatus: '' });
  public actionsFilter$: BehaviorSubject<{ forceReload?: boolean; actionFilters: ActionFilter}> = new BehaviorSubject<{ forceReload?: boolean; actionFilters: ActionFilter}>({} as { forceReload?: boolean; actionFilters: ActionFilter});
  public reloadActions$: EventEmitter<boolean> = new EventEmitter();
  constructor(private baseApi: BaseApiService) {}

  /**
   * Gets the data for consulting firm dashboard
   * @returns Firm dashboard data
   */
  getFirmDashboardData() {
    return this.baseApi.httpGet(firmRoutes.dashboard);
  }

  /**
   * Gets list of consulting firm members by it's unique ID
   * @param firmId Unique ID of the consulting firm
   * @returns List of members of the given consulting firm
   */
  getConsultingFirmMembersById(firmId: string) {
    const url = `${firmRoutes.members}/${firmId}`;
    return this.baseApi.httpGet(url);
  }

  refreshLegalEntities() {
    this.refreshLegalEntities$.next(true);
  }

  /**
   * Refresh risk component when edit or update action occur
   */
  refreshRisk() {
    this.refreshRisk$.next(true);
  }

  /**
   * Gets the data for Legal Entity
   * @returns Firm panel linked legal entity
   */
  getLegalEntities(firmId: string, isLinkedEntity: boolean, paginationData?: { page: number; limit: number; search?: string }) {
    const url = firmRoutes.legalEntities.replace('{firmId}', firmId);
    const params = new URLSearchParams();
    params.append('isLinkedEntities', JSON.stringify(isLinkedEntity));
    if (paginationData?.page >= 0) params.append('page', JSON.stringify(paginationData?.page));
    if (paginationData?.limit) params.append('limit', JSON.stringify(paginationData?.limit));
    if (paginationData?.search) params.append('search', paginationData?.search);
    return this.baseApi.httpGet(`${url}?${params.toString()}`);
  }

  /**
   * Links Approved Legal entity to firm
   * @returns Updated status
   */
  linkLegalEntities(firmId: string, values: object) {
    const url = firmRoutes.legalEntities.replace('{firmId}', firmId);
    return this.baseApi.httpPost(url, values);
  }

  /**
   * Suggest Legal entity to firm
   * @returns Updated status
   */
  suggestLegalEntities(value: object) {
    return this.baseApi.httpPost(firmRoutes.suggestEntity, value);
  }

  /**
   * Updates on boarding status
   * @returns Updates on boarding status
   */
  updateBoardingStatus(firmId: string, value: { legalEntityId: string; onBoardingStatus: string }, isSuperAdmin: boolean = false) {
    const params = new URLSearchParams();
    params.append('isSuperAdmin', JSON.stringify(isSuperAdmin));
    const url = `${firmRoutes.legalEntities.replace('{firmId}', firmId)}?${params?.toString()}`;
    return this.baseApi.httpPut(url, value);
  }

  /**
   * get the particular legal entity details
   */
  getLegalEntityDetails(firmId: string, entityId: string) {
    const url = `${firmRoutes.legalEntity}/${firmId}/${entityId}`;
    return this.baseApi.httpGet(url);
  }

  /**
   * update the legal entity details
   * @param entityId  - id
   * @param value - entity details
   * @returns
   */
  postLegalEnitityDetails(entityId: string, value) {
    const url = `${firmRoutes.updateLegalEntity}/${entityId}`;
    return this.baseApi.httpPut(url, value);
  }

  /**
   * request to edit legal to super Admin
   * @param entityId  - id
   * @param value - entity details
   * @returns
   */
  requestToEditLegalEntity(entityId: string, value) {
    const url = `${firmRoutes.requestTOEditLegalEntity}/${entityId}`;
    return this.baseApi.httpPut(url, value);
  }

  getLegalEntityId(legalEntityId: string) {
    const url = firmRoutes.legalEntityById.replace('{entityId}', legalEntityId);
    return this.baseApi.httpGet(url).pipe(
      map((res) => {
        this.legalEntity$.next(res?.data);
        return res;
      }),
      catchError((err) => {
        return err.message;
      })
    );
  }

  getLegalEntity() {
    return this.legalEntity$.asObservable();
  }

  /**
   * get the client list linked to legal entity
   * @param firmId
   * @param entityId
   * @param values
   * @returns
   */
  getclientOnboadringData(firmId: string, entityId: string, values) {
    const params = new URLSearchParams();
    if (values.page) params.append('limit', values.limit);
    if (values.search) params.append('search', values.search);
    if (values.page) params.append('page', values.page);

    const url = `${firmRoutes.clientListForLegalEntity}/${firmId}/${entityId}?${params.toString()}`;
    return this.baseApi.httpGet(url);
  }

  /**
   * send the request for client to fill the onboarding  questions
   * @param firmId
   * @param entityId
   * @returns
   */
  sendOnboardingRequest(clientId: string, entityId: string) {
    const url = `${firmRoutes.requestData}/${clientId}/${entityId}`;
    return this.baseApi.httpPost(url);
  }

  saveOnboardingData(legalEntityId, clientId, values, isSubmit) {
    const url = `${firmRoutes.onboarding}/${clientId}/${legalEntityId}`;
    return this.baseApi.httpPost(url, { onboardingData: values, isSubmitted: isSubmit });
  }

  /**
   * Activate or deactivate legal entity
   * @param firmId Unique firmId
   * @param legalEntityId Unique legal entity Id
   * @param activate - boolean
   */
  activateOrDeactivateLegalEntity(legalEntityId: string, firmId: string, activate: boolean) {
    const url = firmRoutes.activateOrDeactivateLegalEntity.replace('{legalEntityId}', legalEntityId);
    const value = {
      firmId: firmId,
      activate: activate,
    };
    return this.baseApi.httpPut(`${url}`, value);
  }

  /**
   *  To Fetch Meeting Filter data
   * @returns Meeting Filter
   */
  getMeetingFilterData() {
    return this.meetingsFilter$.asObservable();
  }

  /**
   * Updates Meeting Filter data
   */
  updateMeetingsFilter(data: MeetingFilter) {
    this.meetingsFilter$.next({ ...data });
  }

  getLegalEntityDetailById(firmId: string, legalEntityId: string, tab: string) {
    const params = new URLSearchParams();
    params.append('tab', tab);
    const url = firmRoutes.legalEntityDetailById.replace('{firmId}', firmId).replace('{legalEntityId}', legalEntityId);
    return this.baseApi.httpGet(`${url}?${params.toString()}`);
  }

  updateLegalEntityDetails(firmId: string, legalEntityId: string, body: object, tab: string) {
    const params = new URLSearchParams();
    params.append('tab', tab);
    const url = firmRoutes.legalEntityDetailById.replace('{firmId}', firmId).replace('{legalEntityId}', legalEntityId);
    return this.baseApi.httpPut(`${url}?${params.toString()}`, body);
  }
  /**
   * Get stripe customer portal link for the legal entity
   * @param legalEntityId
   * @returns
   */
  getStripeCustomerPortalLinkForLegalEntity(legalEntityId: string) {
    const params = new URLSearchParams();
    params.append('legalEntityId', legalEntityId);
    return this.baseApi.httpGet(`${firmRoutes.stripeCustomerPortalLink}?${params.toString()}`);
  }

  getClientFirmPartners(firmId: string){
    const url = firmRoutes.clientFirmPartner.replace('{firmId}', firmId);
    return this.baseApi.httpGet(`${url}`)
  }

  /**
   *  To Fetch Action Filter data
   * @returns Action Filter
   */
  getActionFilterData() {
    return this.actionsFilter$.asObservable();
  }

  /**
   * Updates Action Filter data
   */
  updateActionsFilter(data: { forceReload?: boolean; actionFilters: ActionFilter}) {
    this.actionsFilter$.next({ ...data });
  }
}
