import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConSourceModule } from '../interfaces';
import { CLIENT_MODULES } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private permissionDetails$ = new BehaviorSubject<object>({});
  private moduleAccessDetails$ = new BehaviorSubject<ConSourceModule[]>([]);
  private providerModule$ = new BehaviorSubject<ConSourceModule>({} as ConSourceModule);
  constructor() {}

  /**
   * Sets Permission Details
   * @param data permission details
   * @returns
   */
  setUserPermissionData(data: object) {
    if (data) this.permissionDetails$.next(data);
  }

  /**
   * To fetch permission Lists
   */
  getPermissionsList(): Observable<any> {
    return this.permissionDetails$.asObservable();
  }

  get permissionDetails() {
    return this.permissionDetails$.value;
  }

  /**
   * Sets Module Access Permission Details
   * @param data module access details
   */
  setModuleAccessDetails(data: ConSourceModule[]) {
    if (data?.length) {
      this.moduleAccessDetails$.next(data);
      this.providerModule$.next(data.find((module) => module.code === CLIENT_MODULES.PANEL_MANAGEMENT));
    }
  }

  /**
   * To fetch Module Access details
   */
  getModuleAccessDetails(): Observable<any> {
    return this.moduleAccessDetails$.asObservable();
  }

  getPanelManagementModule() {
    return this.providerModule$.asObservable();
  }
}
