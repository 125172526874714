<div class="sow-container w-100 p-20 pt-3" cdkDropList (cdkDropListDropped)="dropSection($event)"
  [cdkDropListDisabled]="disableDragAndDrop || !isEditedOrAdded()">
  <ng-container *ngIf="displayList?.length; else emptySections">
    <ng-container *ngFor="let values of displayList; let i = index; let last = last; trackBy: trackByFn">
      <div class="p-16 dynamic-card pl-0 pr-0" [ngClass]="viewList[i] || !values.isActive ? 'col-12' : 'col-12'" cdkDrag
        cdkDragBoundary=".sow-container"
        [cdkDragDisabled]="disableDragAndDrop || !isEditedOrAdded() || displayList.length < 2 || readOnly || false">
        <div [ngStyle]="{ 'min-height.px': 120 }"
          class="drag-custom-placeholder m-t-12 m-b-24 border-radius-xlarge col-6 pl-0 pr-0" *cdkDragPlaceholder></div>
        <div class="d-flex align-items-center justify-content-between mb-3 mt-1 flex-1 col-6 pl-0 pr-0">
          <div class="d-flex align-items-center" [ngClass]="{ 'flex-1': values?.isNew || !viewList[i] }">
            <div *ngIf="isEditedOrAdded() && !disableDragAndDrop && !readOnly" class="d-flex align-items-center"
              cdkDragHandle>
              <mat-icon class="cursor-move txt-secondary">drag_indicator</mat-icon>
            </div>
            <div *ngIf="viewList[i] || !values.isActive; else editNameRef" class="d-flex">
              <h4 class="f-14 f-w-700 font-Jakarta txt-black-primary px-2" (click)="values.isActive ? onEdit(i) : ''">
                {{ values?.number && !values.isNew ? values.number + '.' : '' }} {{ values.title }}
              </h4>
            </div>
            <ng-template #editNameRef>
              <div class="d-flex w-100">
                <form class="flex-1" #editNameForm="ngForm" name="editNameForm">
                  <mat-form-field class="mb-0 w-100" floatLabel="never">
                    <input matInput [(ngModel)]="values.title"
                      (blur)="values.title = values.title.trim(); checkTitle(i)" name="{{ 'title' + i }}" #titleInput
                      (ngModelChange)="onValueChanges(i)" placeholder="{{ 'Enter Title here' | i18next }}"
                      [required]="true" id="{{ 'title' + i }}" />
                    <mat-error *ngIf="editNameForm.controls['title' + i]?.errors?.required">{{ 'Title is required' |
                      i18next }} </mat-error>
                  </mat-form-field>
                </form>
              </div>
            </ng-template>
          </div>
          <div class="d-flex align-items-center">
            <!-- Title menu -->
            <ng-container *ngIf="values.isActive && getDynamicCommentCount(values._id, commentCountList)">
              <div class="d-flex ml-2 comment" *ngIf="isEditAccess">
                <p>Comments</p>
                <span>{{ getDynamicCommentCount(values._id, commentCountList) }}</span>
              </div>
              <!-- <p class="ml-2 badge-container">
                <mat-icon class="comment-badge" [matBadge]="getDynamicCommentCount(values._id, commentCountList)" matBadgeOverlap="false" matBadgeColor="warn"
                  >chat</mat-icon
                >
              </p> -->
            </ng-container>
            <ng-container *ngIf="isEditAccess">
              <conpulse-web-dynamic-form-validation-chip class="mr-2"
                [validationStatus]="values.isValidated"></conpulse-web-dynamic-form-validation-chip>
              <mat-slide-toggle color="secondary" class="mr-2" *ngIf="!isLoading" [checked]="values.isActive"
                (change)="changeActivationStatus(i)"
                [disabled]="(values.isActive && !viewList[i]) || readOnly"></mat-slide-toggle>
            </ng-container>
            <div *ngIf="!isLoading && values.isActive && isEditAccess" class="m-l-16 title-menu mr-2">
              <a [matMenuTriggerFor]="terminologymenu" class="pointer d-flex" #isMenuOpen="matMenuTrigger">
                <i class="material-icons option-icon mx-2 f-18">more_vert</i>
              </a>
              <mat-menu #terminologymenu="matMenu" class="">
                <button mat-menu-item (click)="openComments(values)"
                  *ngIf="!values.isNew && showAddComments(values?._id)">
                  <mat-icon>chat</mat-icon>{{ 'Comment' | i18next }}
                </button>
                <button class="validate-action" mat-menu-item [hidden]="readOnly" (click)="validateField(values)"
                  *ngIf="isValidateEnabled(values)">
                  <mat-icon class="validate-action">check_circle</mat-icon>{{ 'Validate' | i18next }}
                </button>
                <button mat-menu-item class="delete-action" [hidden]="readOnly" (click)="onDelete(i)">
                  <mat-icon class="delete-action">delete</mat-icon>{{ 'Delete' | i18next }}
                </button>
              </mat-menu>
            </div>
          </div>

        </div>
        <ng-container *ngIf="values.isActive">
          <div class="d-flex justify-content-between">
            <ng-container [ngSwitch]="values.type">
              <ng-container *ngSwitchCase="dynamicFieldDataTypes.QUILL" [ngTemplateOutlet]="QuillRef"></ng-container>
              <ng-container *ngSwitchCase="dynamicFieldDataTypes.UPLOAD" [ngTemplateOutlet]="UploadRef"></ng-container>
              <ng-container *ngSwitchCase="dynamicFieldDataTypes.TEMPLATE"
                [ngTemplateOutlet]="TemplateRef"></ng-container>
            </ng-container>
            <ng-template #QuillRef>
              <div class="w-100" quillToggleShow>
                <div class="text mr-3 w-50" [ngClass]="{ 'txt-grey-secondary7': !values?.commonData?.text?.length }">
                  <div [innerHTML]="
                      values?.commonData?.text ? (values?.commonData?.text | safeHtml) : ('Enter ' | i18next) + (values.title ? values?.title : 'text')
                    "></div>
                  <mat-error class="f-12"
                    *ngIf="editDescForm.controls['quillEditor' + i]?.errors?.required || editDescForm.invalid">{{
                    'Description is required' | i18next
                    }}</mat-error>
                </div>
                <div class="quill-editor-display-status">
                  <div class="d-flex justify-content-between">
                    <div class="msa-quill-height quill-height-200 mb-2 mr-3 w-50">
                      <form #editDescForm="ngForm" name="editDescForm">
                        <quill-editor name="{{ 'quillEditor' + i }}" [placeholder]="('Enter ' | i18next) + values.title"
                          [modules]="RfpQuillConfig" [(ngModel)]="values.commonData.text" [required]="true"
                          [disabled]="readOnly" [trimOnValidation]="true" (ngModelChange)="onValueChanges(i)"
                          (onEditorCreated)="onQuillCreated($event)"></quill-editor>
                        <mat-error
                          *ngIf="editDescForm.controls['quillEditor' + i]?.errors?.required || editDescForm.invalid">{{
                          'Description is required' | i18next
                          }}</mat-error>
                      </form>
                    </div>
                    <div class="w-50 diffbox">
                      <conpulse-web-text-diff [leftHandSide]="getPreviousValues(values)"
                        [rightHandSide]="values?.commonData?.text"></conpulse-web-text-diff>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template #UploadRef>
              <div class="w-100">
                <conpulse-web-upload-docs-or-link [documentDetails]="documentDetails"
                  [documents]="values.commonData?.document" [viewOnly]="viewList[i]"
                  (documentUpdated)="onValueChanges(i)"
                  (download)="downloadDocument($event)"></conpulse-web-upload-docs-or-link>
              </div>
            </ng-template>
            <ng-template #TemplateRef>
              <div class="w-100">
                <div class="mb-4" #templateFormat>
                  <p class="mb-2"
                    [ngClass]="{ 'f-16 f-w-700 font-Jakarta txt-black-primary': !viewList[i], 'f-14 f-w-600': viewList[i] }">
                    {{ 'Template Format' | i18next }}
                  </p>
                  <conpulse-web-upload-docs-or-link [documentDetails]="documentDetails"
                    [documents]="values.commonData?.template" [viewOnly]="viewList[i]"
                    (documentUpdated)="onValueChanges(i)"
                    (download)="downloadDocument($event)"></conpulse-web-upload-docs-or-link>
                </div>
                <div #templateValue
                  *ngIf="(viewList[i] && ((values.commonData?.document)[0]?.key || (values.commonData?.document)[0]?.name)) || !viewList[i]">
                  <p class="mb-2"
                    [ngClass]="{ 'f-16 f-w-700 font-Jakarta txt-black-primary ': !viewList[i], 'f-14 f-w-600': viewList[i] }">
                    {{ 'Template Value' | i18next }}
                  </p>
                  <conpulse-web-upload-docs-or-link [documentDetails]="documentDetails"
                    [documents]="values.commonData?.document" [viewOnly]="viewList[i]"
                    (documentUpdated)="onValueChanges(i)"
                    (download)="downloadDocument($event)"></conpulse-web-upload-docs-or-link>
                </div>
              </div>
            </ng-template>
          </div>
        </ng-container>
      </div>
      <ng-container *ngIf="multipleTypeFields; else SingleTypeFieldRef">
        <div *ngIf="!last && !readOnly; else emptySpace" class="h-24 add-section pointer d-flex align-items-center"
          [ngClass]="viewList[i] || !values.isActive ? 'col-8' : 'col-12'" [matMenuTriggerFor]="newFieldMenu"
          (click)="openMenu($event)" (menuClosed)="resetPosition()" [style.left.px]="menuPosition.x">
          <div class="h-2 width-100 add"></div>
          <div class="mat-8 pointer d-flex align-items-center justify-content-center add-icon">
            <mat-icon>add</mat-icon>
          </div>
        </div>
        <mat-menu #newFieldMenu="matMenu" class="custom-menu">
          <button mat-menu-item (click)="addNewField(i + 1, dynamicFieldDataTypes.QUILL)">{{ 'Rich Text' | i18next
            }}</button>
          <button mat-menu-item (click)="addNewField(i + 1, dynamicFieldDataTypes.UPLOAD)">{{ 'Upload' | i18next
            }}</button>
          <button mat-menu-item (click)="addNewField(i + 1, dynamicFieldDataTypes.TEMPLATE)">{{ 'Template' | i18next
            }}</button>
        </mat-menu>
      </ng-container>
      <ng-template #SingleTypeFieldRef>
        <div *ngIf="!last && !readOnly && !isSectionsValid(); else emptySpace"
          class="h-24 add-section pointer d-flex align-items-center"
          [ngClass]="viewList[i] || !values.isActive ? 'col-6' : 'col-6'"
          (click)="addNewField(i + 1, dynamicFieldDataTypes.QUILL)">
          <div class="h-2 width-100 add"></div>
          <div class="mat-8 pointer d-flex align-items-center justify-content-center add-icon">
            <mat-icon>add</mat-icon>
          </div>
        </div>
      </ng-template>
      <ng-template #emptySpace>
        <div class="h-24"></div>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="multipleTypeFields; else SingleTypeAddRef">
      <button *ngIf="!readOnly" type="button" mat-stroked-button color="primary" class="m-t-24 medium"
        [matMenuTriggerFor]="newFieldAtLastMenu">
        <mat-icon>add</mat-icon> {{ 'Add Field' | i18next }}
      </button>
      <mat-menu #newFieldAtLastMenu="matMenu">
        <button mat-menu-item
          (click)="addNewField(displayList?.length ? displayList.length : 0, dynamicFieldDataTypes.QUILL)">
          {{ 'Rich Text' | i18next }}
        </button>
        <button mat-menu-item
          (click)="addNewField(displayList?.length ? displayList.length : 0, dynamicFieldDataTypes.UPLOAD)">
          {{ 'Upload' | i18next }}
        </button>
        <button mat-menu-item
          (click)="addNewField(displayList?.length ? displayList.length : 0, dynamicFieldDataTypes.TEMPLATE)">
          {{ 'Template' | i18next }}
        </button>
      </mat-menu>
    </ng-container>
    <ng-template #SingleTypeAddRef>
      <button *ngIf="!readOnly && isEditAccess " type="button" mat-stroked-button color="secondary" class="medium m-t-24 mb-5"
        [disabled]="isSectionsValid()"
        (click)="addNewField(displayList?.length ? displayList.length : 0, dynamicFieldDataTypes.QUILL)">
        <mat-icon class="mat-20 mr-2">add</mat-icon> {{ buttonName | i18next }}
      </button>
    </ng-template>
  </ng-container>
  <ng-template #emptySections>
    <div class="no-data">
      <div class="vector-image">
        <img [src]="notFoundImage" class="no-data" alt="" />
      </div>
      <h4 class="heading-empty mb-2">{{ "You don't have any data!" | i18next }}</h4>
      <ng-container *ngIf="isEditAccess">
        <p class="subtitle">{{ 'Click on Add Provision to add one.' | i18next }}.</p>
        <button *ngIf="!readOnly" type="button" mat-stroked-button color="secondary" class="medium m-t-24 mb-5"
          [disabled]="isSectionsValid()" (click)="addNewField(0, dynamicFieldDataTypes.QUILL)">
          <mat-icon class="mat-20 mr-2">add</mat-icon> {{ buttonName | i18next }}
        </button>
      </ng-container>
    </div>
  </ng-template>
  <div *ngIf="!readOnly && displayList?.length && isEditAccess"
    class="bottom-stick bg-white border-top d-flex align-items-center justify-content-end pl-3 p-r-72 py-3">
    <button mat-flat-button color="primary" (click)="saveSectionDetails()"
      [disabled]="isEditedOrAdded() || isSectionsValid()">
      {{ 'Save' | i18next }}
    </button>
  </div>
</div>