import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminService, AvailableModulesList, CONSTANTS, EnabledModuleInfo, NotificationService, UserService, user } from '@conpulse-web/core';
import { UtilityMethodsService } from '../../services';
import { MatDialog } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { cloneDeep, isEqual } from 'lodash-es';

@Component({
  selector: 'conpulse-web-client-module-management',
  templateUrl: './client-module-management.component.html',
  styleUrls: ['./client-module-management.component.scss'],
})
export class ClientModuleManagementComponent implements OnInit {
  @ViewChild('form', { static: true }) form: NgForm;
  user: user = {} as user;
  isLoading: boolean = false;
  AvailableModulesList = AvailableModulesList;
  enabledModuleInfo: EnabledModuleInfo = {} as EnabledModuleInfo;
  initialEnabledModuleInfo: EnabledModuleInfo = {} as EnabledModuleInfo;
  routerSubscription: Subscription | null = null;
  isSuperAdmin: boolean = false;
  companyId: string = '';

  constructor(
    private userService: UserService,
    private utilityService: UtilityMethodsService,
    private notificationService: NotificationService,
    private adminService: AdminService,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && event.url) {
        const route = event.url.split('/');
        this.isSuperAdmin = false;
        if (!CONSTANTS.CLIENT_USER_ROLES.includes(this.userService.currentUserInformation?.role)) {
          this.isSuperAdmin = true;
          this.companyId = route[route.length - 3];
        }
      }
    });
  }

  ngOnInit(): void {
    this.user = this.userService.currentUserInformation;
    const route = this.router.url.split('/');
    this.isSuperAdmin = false;
    if (!CONSTANTS.CLIENT_USER_ROLES.includes(this.user?.role)) {
      this.isSuperAdmin = true;
      this.companyId = route[route.length - 3];
    }
    this.loadClientModuleDetails();
  }

  /**
   * Loads necessary filter dropdown values
   */
  loadClientModuleDetails() {
    this.isLoading = true;
    this.userService.getEnabledModulesForClient(this.isSuperAdmin ? this.companyId : this.user?.companyId?.['_id'], true).subscribe((response) => {
      this.enabledModuleInfo = response;
      this.initialEnabledModuleInfo = cloneDeep(this.enabledModuleInfo);
      if (this.enabledModuleInfo) {
        this.isLoading = false;
      } else {
        this.notificationService.openErrorSnackBar('Enabled modules information not available for selected client account');
      }
    });
  }

  saveClientModuleDetails() {
    this.isLoading = true;
    this.userService.saveEnabledModulesForClient(this.enabledModuleInfo).subscribe(
      (response) => {
        this.loadClientModuleDetails();
        this.isLoading = false;
      },
      (error) => this.notificationService.openErrorSnackBar(error?.message || error)
    );
  }

  /**
   * Checks if save button is disabled or not
   * @returns Boolean value indicating whether the button is disabled
   */
  checkButtonDisabled() {
    return isEqual(this.initialEnabledModuleInfo, this.enabledModuleInfo);
  }

  getClass = (index: number) => this.utilityService.getClass(index);

  /**
   * Determines whether a module is enabled/disabled
   * @param moduleId Parent Module Identifier
   * @param childModuleId Child Module Identifier
   */
  isModuleEnabled(moduleId: string, childModuleId?: string) {
    // check parent module enabled
    const enabledParentModule = this.enabledModuleInfo?.enabledModules?.find((module) => module.moduleId === moduleId) || null;
    if (!childModuleId) return enabledParentModule !== null;
    if (enabledParentModule) {
      const enabledChildModule = enabledParentModule?.children?.find((module) => module === childModuleId) || null;
      return enabledChildModule !== null;
    }
    return false;
  }

  /**
   * Checks whether some child modules are enabled
   * @param moduleId Module ID
   */
  checkIndeterminate(moduleId: string) {
    const parentModule = this.enabledModuleInfo?.enabledModules?.find((module) => module.moduleId === moduleId) || null;
    const parentAvailableModule = AvailableModulesList.find((module) => module.moduleId === moduleId);
    const currentAvailableModuleIds = new Set(parentAvailableModule?.children?.map((module) => module.moduleId));
    const validParentModuleIds = parentModule?.children?.filter((moduleId) => currentAvailableModuleIds.has(moduleId));
    if (parentModule == null || !parentModule?.children?.length) return false;
    if (validParentModuleIds?.length < parentAvailableModule?.children?.length) return true;
  }

  /**
   * Checks whethre all child modules are enabled
   * @param moduleId Module ID
   * @returns
   */
  checkAllEnabled(moduleId: string) {
    const parentModule = this.enabledModuleInfo?.enabledModules?.find((module) => module.moduleId === moduleId) || null;
    const parentAvailableModule = AvailableModulesList.find((module) => module.moduleId === moduleId);
    const currentAvailableModuleIds = new Set(parentAvailableModule?.children?.map((module) => module.moduleId));
    const validParentModuleIds = parentModule?.children?.filter((moduleId) => currentAvailableModuleIds.has(moduleId));
    if (parentModule == null) return false;
    if (!parentAvailableModule?.children?.length) return true;
    if (parentAvailableModule?.children?.every((module) => validParentModuleIds?.includes(module.moduleId))) return true;
  }

  /**
   * Enable/disable module for client
   * @param isEnable Enable/disable flag
   * @param moduleId Module ID
   * @param childModuleId Child Module ID
   */
  enableOrDisableModule(isEnable: boolean, moduleId: string, childModuleId?: string) {
    const parentModule = AvailableModulesList.find((module) => module.moduleId === moduleId);
    const parentAlreadyExists = this.enabledModuleInfo?.enabledModules?.find((module) => module.moduleId === moduleId);
    if (parentModule) {
      if (isEnable) {
        if (!childModuleId) {
          if (!parentAlreadyExists) {
            let pushedModule = this.enabledModuleInfo.enabledModules[this.enabledModuleInfo.enabledModules.push({ moduleId }) - 1];
            if (parentModule?.children?.length) pushedModule['children'] = parentModule.children.map((module) => module.moduleId);
          }
        } else {
          const childModule = parentModule?.children?.find((module) => module.moduleId === childModuleId);
          if (childModuleId === 'NDA_WIZARD' || childModuleId === 'MSA_WIZARD') {
            let childIndex = parentAlreadyExists?.children?.findIndex((moduleId) => moduleId === 'CONTRACTS_LIST');
            if (childIndex < 0)
              parentAlreadyExists?.children ? parentAlreadyExists.children?.push('CONTRACTS_LIST') : (parentAlreadyExists.children = ['CONTRACTS_LIST']);
          }
          if (parentAlreadyExists && childModule) {
            parentAlreadyExists?.children ? parentAlreadyExists.children?.push(childModuleId) : (parentAlreadyExists.children = [childModuleId]);
          }
        }
      } else {
        if (!childModuleId) {
          if (parentAlreadyExists) {
            const parentIndex = this.enabledModuleInfo?.enabledModules?.findIndex((module) => module.moduleId === moduleId);
            if (parentIndex >= 0) this.enabledModuleInfo.enabledModules.splice(parentIndex, 1);
          }
        } else {
          if (parentAlreadyExists) {
            const childIndex = parentAlreadyExists?.children?.findIndex((moduleId) => moduleId === childModuleId);
            if (childIndex >= 0) parentAlreadyExists?.children?.splice(childIndex, 1);
            if (childModuleId === 'CONTRACTS_LIST') {
              let childIndex = parentAlreadyExists?.children?.findIndex((moduleId) => moduleId === 'NDA_WIZARD');
              if (childIndex >= 0) parentAlreadyExists?.children?.splice(childIndex, 1);
              childIndex = parentAlreadyExists?.children?.findIndex((moduleId) => moduleId === 'MSA_WIZARD');
              if (childIndex >= 0) parentAlreadyExists?.children?.splice(childIndex, 1);
            }
            if (!parentAlreadyExists?.children?.length) this.enableOrDisableModule(isEnable, moduleId);
          }
        }
      }
    }
  }

  openClientAdminDrawer() {
    this.adminService.setAccountDrawerState({
      open: true,
      type: 'change-admin',
    });
  }
}
