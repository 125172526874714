import { CONSTANTS } from '@conpulse-web/core';

export const environment = {
  production: true,
  APIEndpoint: 'https://dev-api.consource.io/api/',
  systemBase: 'https://uiuxdevtest.consource.io/',
 auth: {
      domain: 'consourse-dev.us.auth0.com',
      clientId: 'ArVnI0yjXNbluqoMMSKiUGNevci6Ekyc',
      audience: 'https://consourse-dev.us.auth0.com/api/v2/',
      redirectUri: 'https://uiuxdevtest.consource.io/'
    },
  socketUrl: 'https://2da6-210-18-157-68.ngrok-free.app/',
  httpInterceptorUrl: 'https://dev-api.consource.io/api/*'
};
