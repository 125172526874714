import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[quillToggleShow]'
})
export class QuillShowHideDirective {
  
  @Input() isTextDisabled = false;
  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('click') onClick() {
    if(!this.isTextDisabled) this.showHideDiv();
  }

  private showHideDiv() {
    const clickedDiv = this.el.nativeElement;
    const textNode = clickedDiv.querySelector('div.text');
    const editorNode = clickedDiv.querySelector('div.quill-editor-display-status');

    if (textNode && editorNode) {
      this.renderer.setStyle(textNode, 'display', 'none');
      this.renderer.setStyle(editorNode, 'display', 'block');
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    if (!this.el.nativeElement.contains(event.target)) {
      const textNode = this.el.nativeElement.querySelector('.text');
      const editorNode = this.el.nativeElement.querySelector('.quill-editor-display-status');

      if (textNode && editorNode) {
        this.renderer.setStyle(textNode, 'display', 'block');
        this.renderer.setStyle(editorNode, 'display', 'none');
      }
    }
  }
}
