<div class="row">
  <div class="col-12">
    <mat-form-field class="full-width mb-2" floatLabel="never"
    [ngClass]="{'skeleton-loader skeleton-title w-100 h-auto' : loading}">
      <mat-select [disabled]="isDisabled" (openedChange)="search('')" [placeholder]="matSelectPlaceHolder"
        [required]="isSelectMandatory" (selectionChange)="doneSelect(matSelectedItems, false)"
        [(ngModel)]="matSelectedItems" name="select" color="primary" multiple>
        <mat-form-field class="full-width search-clear mb-0" floatLabel="never">
          <span matPrefix class="search-icon"><i class="material-icons">search</i></span>
          <input [placeholder]="matInputPlaceHolder" matInput [(ngModel)]="matSearchText"
            (keyup)="search(matSearchText)" (keydown)="$event.stopPropagation()">
          <span (click)="clearSearch()" [hidden]="check()" matSuffix class="pointer close-icon">Clear All<i
                class="material-icons">close</i></span>
        </mat-form-field>
        <p class="no-found" *ngIf="dropDownItems?.length === 0">No Search Found!</p>
        <ng-container *ngIf="!isIndustryCapability else industryCapability">
          <mat-optgroup *ngFor="let country of dropDownItems; let i = index;">
<mat-checkbox color="secondary"
            (change)="handleChangeCheckbox($event.checked, country._id)"
            [checked]="isSelectedAll(country._id)" [indeterminate]="isIntermediate(country._id)">
            {{country.name | titlecase}}
          </mat-checkbox>
          <span class="arrow" matSuffix *ngIf="item.children.length">
            <i (click)="activeToggler(i); $event.preventDefault()"class="material-icons">{{ i === activeClassIndex? "keyboard_arrow_up" :
              "keyboard_arrow_down"}}</i>
            </span>
            <mat-option class="customize-options ml-4" [class.active]="i === activeClassIndex" mat-action-row
              *ngFor="let state of country.states" [value]="state._id">
              {{state.name | titlecase}}
            </mat-option>
          </mat-optgroup>
        </ng-container>
        <ng-template #industryCapability>
          <mat-optgroup *ngFor="let item of dropDownItems; let i = index;">
<mat-checkbox color="secondary"
            (change)="handleChangeCheckbox($event.checked, item._id)"
            [checked]="isSelectedAll(item._id)" [indeterminate]="isIntermediate(item._id)">
            {{item.name | titlecase}}
          </mat-checkbox>
          <span class="arrow" matSuffix *ngIf="item.children.length">
            <i (click)="activeToggler(i); $event.preventDefault()"class="material-icons">{{ i === activeClassIndex? "keyboard_arrow_up" :
              "keyboard_arrow_down"}}</i>
            </span>
            <ng-container *ngIf="item.children.length else parentOption">
              <mat-option class="customize-options ml-4" [class.active]="i === activeClassIndex" mat-action-row
              *ngFor="let child of item.children" [value]="child._id">
              {{child.name | titlecase}}
            </mat-option>
            </ng-container>
            <ng-template #parentOption>
              <mat-option class="customize-options ml-4" [class.active]="i === activeClassIndex" mat-action-row [value]="item._id" style="display: none !important;">
              {{ item.name | titlecase }}
              </mat-option>
            </ng-template>
          </mat-optgroup>
        </ng-template>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div class="d-flex flex-wrap account-content" *ngIf="selectedDisplayItems?.length">
  <div class="tag-clouds" [class.border-line]="selectedDisplayItems?.length > 5">
    <mat-chip-list>
      <ng-container *ngIf="!isIndustryCapability else industryCapability">
        <ng-container *ngFor="let item of selectedDisplayItems">
          <mat-chip color="warn-color" *ngFor="let state of item.states" matTooltip="{{state.countryName}}"
            matTooltipClass="example-tooltip-red" matTooltipPosition="above"
            class="example-button">
            <img class="flag mr-1" [src]="flagUrl + state.isoCode.toLowerCase() + '.png'">
            {{state.name | titlecase}}
            <mat-icon [hidden]="isDisabled" matChipRemove (click)="removeSelected(state._id)">
              close
            </mat-icon>
          </mat-chip>
        </ng-container>
      </ng-container>
      <ng-template #industryCapability>
        <ng-container *ngFor="let item of selectedDisplayItems">
          <mat-chip color="warn-color" *ngFor="let child of item.children" matTooltip="{{child.parentName | titlecase}}"
            matTooltipClass="example-tooltip-red" matTooltipPosition="above"
            class="example-button">
            {{child.name | titlecase}}
            <mat-icon [hidden]="isDisabled" matChipRemove (click)="removeSelected(child._id)">
              close
            </mat-icon>
          </mat-chip>
        </ng-container>
      </ng-template>
    </mat-chip-list>
  </div>
</div>
