import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { UtilityMethodsService } from '../../services/utility-methods.service';
import { NgForm } from '@angular/forms';
import { filter, isEmpty, remove } from 'lodash-es';

@Component({
  selector: 'app-con-filter-select',
  templateUrl: './filter-select.component.html',
  styleUrls: ['./filter-select.component.scss'],
})
export class FilterSelectComponent implements OnInit {
  originalItems: object[];
  dropDownItems: object[];
  matSelectedItems: string[];
  selectedDisplayItems: object[];
  selectedItems: string[];
  @Input() displayField = 'name';
  @Input() uniqueField = '_id';
  @Input() isDisabled = false;
  @Input() type: string;
  @Input() loading = true;
  @Input() isSelectMandatory: boolean;
  @Input() matInputPlaceHolder: string;
  @Input() matSelectPlaceHolder: string;
  @Input() matSearchText: string;
  @Input() isMultiple: boolean = true;
  @Output() selectComplete = new EventEmitter();
  @Input() isWorkFlow = false;
  @Input() isFromDemand = false;
  @ViewChild('filterSelect') selectForm: NgForm;

  showMoreChips = false;

  @Input()
  set items(value: object[]) {
    if (value) {
      this.dropDownItems = this.originalItems = value;
    }
  }

  @Input()
  set markedItems(value: string[]) {
    if (value) {
      this.selectedItems = this.matSelectedItems = value;
      this.doneSelect(value, true);
    }
  }

  constructor(private utilityMethod: UtilityMethodsService) {}

  ngOnInit() {}

  search(searchText): void {
    this.dropDownItems = [];
    this.matSearchText = searchText;
    this.dropDownItems = this.utilityMethod.searchList(searchText, this.originalItems, this.displayField);
  }

  clearSearch(): void {
    this.matSearchText = '';
    this.dropDownItems = this.originalItems;
  }

  removeSelected(id): void {
    remove(this.selectedDisplayItems, { [this.uniqueField]: id });
    this.selectedItems = this.matSelectedItems = filter(this.selectedItems, (singleId) => {
      return singleId !== id;
    });
    this.showMoreChips = this.selectedItems.length > 3;
    this.selectComplete.emit({ type: this.type, items: this.selectedItems, id: id });
  }

  doneSelect(matSelectedItems, isFirstChange): void {
    if(this.isMultiple){
      const customFilterResponse = this.utilityMethod.filterSelect(
        this.selectedItems,
        this.originalItems,
        this.dropDownItems,
        matSelectedItems,
        this.displayField,
        this.uniqueField
      );
      this.selectedDisplayItems = customFilterResponse.displayItems;
      this.selectedItems = this.matSelectedItems = customFilterResponse.selectedItemArray;
    }else{
      this.selectedItems = this.matSelectedItems;
    }
    if (!isFirstChange) {
      this.selectComplete.emit({ type: this.type, items: this.selectedItems });
    }
  }

  check() {
    return isEmpty(this.matSearchText);
  }
}
