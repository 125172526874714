import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiResponse } from '../interfaces/common-interfaces';
import { NotificationService } from '../notification/notification.service';
import { get, isEmpty } from 'lodash-es';

const templateLibrary = {
  library: 'company/projects/settings/library',
  libraryDuplicate: 'company/projects/settings/library-duplicate',
  createFromExisting: 'company/projects/settings/create-from-existing',
};

@Injectable({
  providedIn: 'root',
})
export class BaseApiService {
  private defaultOptions: object;
  public selectedEmailLanguageId: string = '';
  public selectedQuestionLanguageId: string = '';
  public notificationIntervalTimer: ReturnType<typeof setInterval>;

  private handleError(error: Response): any {
    if (error.status === 0 || error.statusText === 'Unknown Error') {
      this.notificationService.openErrorSnackBar('Check your Network connection. Please try again');
      return throwError({
        message: 'Check your Network connection. Please try again',
      });
    }
    return throwError(
      { ...get(error, 'error'), code: get(error, 'status') } || {
        message: 'Check your Network connection. Please try again',
      }
    );
  }

  constructor(private http: HttpClient, private notificationService: NotificationService) {
    this.defaultOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
      withCredentials: true,
    };
  }

  /* Base HTTP APIs */

  /**
   * Performs HTTP GET Operation
   * @param url URL
   * @returns HTTP Response
   */
  httpGet(url: string): Observable<any> {
    return this.http.get<ApiResponse>(url, this.defaultOptions).pipe(
      map((res) => res),
      catchError((err) => this.handleError(err))
    );
  }

  /**
   * Performs HTTP POST Operation
   * @param url URL
   * @returns HTTP Response
   */
  httpPost(url: string, data?: object): Observable<any> {
    return this.http.post<ApiResponse>(url, data, this.defaultOptions).pipe(
      map((res) => res),
      catchError((err) => this.handleError(err))
    );
  }

  /**
   * Performs HTTP PUT Operation
   * @param url URL
   * @returns HTTP Response
   */
  httpPut(url: string, data?: any, customOptions?: object): Observable<any> {
    return this.http.put<ApiResponse>(url, data, !isEmpty(customOptions) ? customOptions : this.defaultOptions).pipe(
      map((res) => res),
      catchError((err) => this.handleError(err))
    );
  }

  /**
   * Performs HTTP DELETE Operation
   * @param url URL
   * @returns HTTP Response
   */
  httpDelete(url: string, data?: any): Observable<any> {
    return this.http
      .request<ApiResponse>('DELETE', url, { ...this.defaultOptions, body: data })
      .pipe(
        map((res) => res),
        catchError((err) => this.handleError(err))
      );
  }
}
