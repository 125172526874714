<div class="row">
  <div class="col-12">
    <mat-form-field class="full-width mb-2" floatLabel="never"
      [ngClass]="{'skeleton-loader skeleton-title w-100 h-auto' : loading}">
      <mat-select [disabled]="isDisabled" (openedChange)="search('')" [placeholder]="matSelectPlaceHolder"
        [required]="isSelectMandatory" (selectionChange)="doneSelect(matSelectedItems, false)"
        [(ngModel)]="matSelectedItems" name="select" color="primary" multiple>
        <mat-form-field class="full-width sticky-header mb-2" floatLabel="never">
          <mat-icon matPrefix>search</mat-icon>
          <input [placeholder]="matInputPlaceHolder" matInput [(ngModel)]="matSearchText"
            (keyup)="search(matSearchText)" (keydown)="$event.stopPropagation()">
          <mat-icon matSuffix class="pointer" (click)="clearSearch()" [hidden]="check()">close</mat-icon>
        </mat-form-field>
        <p class="no-found" *ngIf="dropDownItems?.length === 0">No Search Found!</p>
        <mat-option [class.active]="i === activeClassIndex" mat-action-row
          *ngFor="let country of dropDownItems; let i = index;" [value]="country._id">
          {{country.name | titlecase}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<ng-container *ngIf="!showEllipisis; else ellipisisChip">
  <div class="d-flex flex-wrap account-content" *ngIf="selectedDisplayItems?.length">
    <div class="tag-clouds" [class.border-line]="selectedDisplayItems?.length > 5">
      <mat-chip-list>
        <mat-chip color="warn-color" *ngFor="let country of selectedDisplayItems"
          matTooltip="{{country?.region?.name | titlecase}}" matTooltipClass="example-tooltip-red"
          matTooltipPosition="above" class="example-button">
          <img class="flag mr-1" [src]="flagUrl + country.isoCode?.toLowerCase() + '.png'">
          {{country.name | titlecase}}
          <mat-icon [hidden]="isDisabled" matChipRemove (click)="removeSelected(country._id)">
            close
          </mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
  </div>
</ng-container>
<ng-template #ellipisisChip>
  <div class="mb-3">
    <div *ngIf="!showMoreChips;else showAll" class="mb-8">
      <mat-chip-list>
        <ng-container *ngFor="let country of selectedDisplayItems; let i = index">
          <ng-container *ngIf="i < 3">
            <mat-chip color="warn-color" matTooltip="{{country?.region?.name | titlecase}}"
              matTooltipClass="example-tooltip-red" matTooltipPosition="above" class="example-button">
              <img class="flag mr-1" [src]="flagUrl + country.isoCode.toLowerCase() + '.png'">
              {{country.name | titlecase}}
              <mat-icon [hidden]="isDisabled" matChipRemove (click)="removeSelected(country._id)">
                close
              </mat-icon>
            </mat-chip>
          </ng-container>
        </ng-container>
      </mat-chip-list>
      <p class="p-title txt-secondary pointer ml-1 mt-2" *ngIf="selectedDisplayItems?.length > 3 && !showMoreChips"
        (click)="showMoreChips = true">
        +{{ selectedDisplayItems.length - 3 }} {{ 'more' | i18next }}
      </p>
    </div>
    <ng-template #showAll>
      <div class="tag-clouds mb-8" [class.border-line]="selectedDisplayItems?.length > 5">
        <mat-chip-list>
          <mat-chip color="warn-color" matTooltip="{{country?.region?.name | titlecase}}"
            matTooltipClass="example-tooltip-red" matTooltipPosition="above" class="example-button"
            *ngFor="let country of selectedDisplayItems; let i = index">
            <img class="flag mr-1" [src]="flagUrl + country.isoCode.toLowerCase() + '.png'">
            {{country.name | titlecase}}
            <mat-icon [hidden]="isDisabled" matChipRemove (click)="removeSelected(country._id)">
              close
            </mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>
      <p class="p-title txt-secondary pointer mt-1 ml-1 mb-2" (click)="showMoreChips = false">
        {{ 'Show less' | i18next }}
      </p>
    </ng-template>
  </div>
</ng-template>
