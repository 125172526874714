import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { cloneDeep, isEmpty, isEqual } from 'lodash-es';
import {
  DeleteConfirmationComponent,
  DynamicFieldDataTypes,
  FieldKeyBasedOnType,
  SoWFieldValidationData,
  SowDynamicFormsValue,
  ContentStyle,
  ConfigService,
  ASSETS,
} from '@conpulse-web/core';
import { CdkDragDrop, CdkDragStart } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { CommonService, UtilityMethodsService } from '@conpulse-web/conpulse';

@Component({
  selector: 'conpulse-web-dynamic-forms-and-lists',
  templateUrl: './dynamic-forms-and-lists.component.html',
  styleUrls: ['./dynamic-forms-and-lists.component.scss'],
})
export class DynamicFormsAndListsComponent implements OnInit, AfterViewChecked {
  isEmpty = isEmpty;
  notFoundImage: string;
  displayList: SowDynamicFormsValue[] = [];
  initialDisplayLists: SowDynamicFormsValue[] = [];
  dynamicFieldDataTypes = DynamicFieldDataTypes;
  documentDetails = { size: 10 * 1024 * 1024, allowedFiles: ['.pdf'] };
  @Input() set dynamicFormLists(values: SowDynamicFormsValue[]) {
    this.displayList = JSON.parse(JSON.stringify([...values]));
    this.initialDisplayLists = JSON.parse(JSON.stringify([...this.displayList]));
    this.disableDragAndDrop = this.displayList.some(
      (val, index) => val.isActive && !this.viewList[index] && isEmpty(val?.commonData?.[FieldKeyBasedOnType[val.type]])
    );
  }
  @Input() isInvitedFirm: boolean;
  @Input() buttonName: string = 'Add Field';
  @Input() set updateActiveStatus(values: { isActive: boolean; fieldId: string }) {
    if (values?.fieldId) {
      this.updateActivationStatus(values);
    }
  }
  @Input() viewList: Array<boolean> = [];
  @Input() tabNumber: number;
  @Input() commentCountList = [];
  @Input() quillConfig = null;
  @Input() style: any;
  @Input() readOnly: boolean = false;
  @Input() multipleTypeFields: boolean = false;
  @Input() isEditAccess : boolean = false;
  @Output() onDeleteSectionById: EventEmitter<string> = new EventEmitter();
  @Output() onSaveDetails: EventEmitter<{ added: SowDynamicFormsValue[]; edited: SowDynamicFormsValue[] }> = new EventEmitter();
  @Output() onDragAndDrop: EventEmitter<{ currentIndex: number; moveToIndex: number }> = new EventEmitter();
  @Output() onActivationToggle: EventEmitter<{ isActive: boolean; fieldId: string }> = new EventEmitter();
  @Output() onOpenComments: EventEmitter<{ fieldName: string; fieldId: string }> = new EventEmitter();
  @Output() onValidated: EventEmitter<{ fieldName: string; fieldId: string }> = new EventEmitter();
  @Output() downloadDoc: EventEmitter<{ key: string }> = new EventEmitter();
  @Output() onEditorCreated = new EventEmitter();
  sectionDragPlaceholderHeight: number = 100;
  disableDragAndDrop: boolean;
  RfpQuillConfig;
  menuPosition = { x: 0, y: 0 };
  index = 0;
  sample = true;
  getDynamicCommentCount = this.commonService.getDynamicCommentCount;
  checkQuillValueIsEmpty = this.commonService.checkQuillValueIsEmpty;
  contentStyle: ContentStyle = { fontStyle: '', fontSize: '', fontFamily: '', color: '' };
  @ViewChild('titleInput', { static: false }) titleInput: ElementRef;

  constructor(
    private readonly dialog: MatDialog,
    private commonService: CommonService,
    private readonly utilityService: UtilityMethodsService,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.notFoundImage = `${this.configService.s3PublicUrl}${ASSETS.NO_DATA_FOUND_IMAGE_PATH}`;
    this.contentStyle = this.style.layout.contentStyle;
    this.RfpQuillConfig = this.quillConfig ? this.quillConfig : this.utilityService.getQuillConfig(this.contentStyle);
  }
  /**
   * Updates the enable and disable status of the section
   * @param index Index ranges (0 - displayList.length -1)
   */
  changeActivationStatus(index: number) {
    this.onActivationToggle.emit({ isActive: !this.displayList[index]?.isActive, fieldId: this.displayList[index]._id });
  }

  /**
   * Focus on the input element after it's initialized
   */
  ngAfterViewChecked() {
    if (this.titleInput) {
      this.titleInput.nativeElement.focus();
    }
  }

  /**
   * Updates activation status
   * @param fieldId FieldId
   */
  updateActivationStatus(values: { isActive: boolean; fieldId: string }) {
    const index = this.displayList.findIndex((displayItem) => values.fieldId === displayItem._id);
    if (index > -1) {
      this.displayList[index].isActive = values.isActive;
      this.displayList[index]?.commonData?.text?.length === 0 && (this.viewList[index] = false);
      let count = 0;
      this.viewList = [];
      this.displayList = this.displayList.map(
        (data: { _id: string; type: DynamicFieldDataTypes; commonData: { text: string }; isActive: boolean; number: string; isNew?: boolean }) => {
          this.viewList.push(!isEmpty(data.commonData?.[FieldKeyBasedOnType[data.type]]) ? true : false);
          if (data.isActive && !data?.isNew) {
            count += 0.1;
            return { ...data, number: (count + this.tabNumber).toFixed(1).toString() };
          } else {
            return { ...data, number: 0 };
          }
        }
      );
      this.disableDragAndDrop = this.viewList.some((val) => val === false);
    }
  }

  /**
   * set default font,size,style for quill
   * @param event
   */
  onQuillCreated(quillEditor: any) {
    if (this.contentStyle) {
      quillEditor.format('font', this.contentStyle.fontFamily);
      quillEditor.format('size', this.contentStyle.fontSize);
      if (Array.isArray(this.contentStyle.fontStyle)) {
        this.contentStyle.fontStyle.forEach((style) => {
          quillEditor.format(style, true);
        });
      }
      quillEditor.format('color', this.contentStyle.color);
    }
  }
  /**
   * Determines whether validate option is enabled or not
   * @param fieldData SoW Field Two Party Data
   * @returns Boolean value indicating if validate is enabled or not
   */
  isValidateEnabled(fieldData: SoWFieldValidationData) {
    if (fieldData.type === this.dynamicFieldDataTypes.UPLOAD) {
      return (
        !isEqual(
          this.isInvitedFirm
            ? fieldData?.firmData?.[FieldKeyBasedOnType[fieldData.type]]?.['key']
            : fieldData?.clientData?.[FieldKeyBasedOnType[fieldData.type]]?.['key'],
          fieldData?.commonData?.[FieldKeyBasedOnType[fieldData.type]]?.[0]?.['key']
        ) && fieldData?.isValidated === 2
      );
    } else if (fieldData.type === this.dynamicFieldDataTypes.TEMPLATE) {
      return (
        (!isEqual(
          fieldData?.[this.isInvitedFirm ? 'firmData' : 'clientData']?.[FieldKeyBasedOnType[fieldData.type]]?.['key'],
          fieldData?.commonData?.[FieldKeyBasedOnType[fieldData.type]]?.[0]?.['key']
        ) ||
          !isEqual(
            fieldData?.[this.isInvitedFirm ? 'firmData' : 'clientData']?.[FieldKeyBasedOnType[this.dynamicFieldDataTypes.UPLOAD]]?.['key'],
            fieldData?.commonData?.[FieldKeyBasedOnType[this.dynamicFieldDataTypes.UPLOAD]]?.[0]?.['key']
          )) &&
        fieldData?.isValidated === 2
      );
    }
    return (
      !isEqual(
        this.isInvitedFirm ? fieldData?.firmData?.[FieldKeyBasedOnType[fieldData.type]] : fieldData?.clientData?.[FieldKeyBasedOnType[fieldData.type]],
        fieldData?.commonData?.[FieldKeyBasedOnType[fieldData.type]]
      ) && fieldData?.isValidated === 2
    );
  }

  /**
   * Switches the view between edit and view
   * @param index Index ranges (0 - displayList.length -1)
   */
  onEdit(index: number) {
    this.viewList[index] = !this.viewList[index];
    this.disableDragAndDrop = this.viewList.some((val) => val === false);
    this.index = index;
  }

  /**
   * Check if title is empty or not if empty throw error
   * @param index -index number
   */
  checkTitle(index) {
    if (this.displayList[index].title) {
      this.onEdit(index);
    }
  }

  /**
   * Event handler for section drag event
   * @param sectionDragEvent
   */
  sectionDragStarted(sectionDragEvent: CdkDragStart) {
    this.sectionDragPlaceholderHeight = sectionDragEvent.source.element.nativeElement.offsetHeight;
  }

  /**
   * Drag/drop event for section
   * @param event DragDrop event
   */
  dropSection(event: CdkDragDrop<void>) {
    if (event.previousIndex !== event.currentIndex) {
      // const previousLists = cloneDeep(this.displayList);
      // moveItemInArray(this.displayList, event.previousIndex, event.currentIndex);
      this.onDragAndDrop.emit({ currentIndex: event.previousIndex, moveToIndex: event.currentIndex });
    }
  }

  /**
   * Updates edited status of the section from the initial value
   * @param index Index ranges (0 - displayList.length -1)
   */
  onValueChanges(index: number) {
    const currentItem = this.displayList[index];
    if (currentItem && currentItem?._id) {
      const foundItemIndex = this.initialDisplayLists.findIndex((displayItem) => displayItem?._id === currentItem._id);
      if (foundItemIndex > -1) {
        const initialItem = this.initialDisplayLists[foundItemIndex];
        this.displayList[index] = {
          ...currentItem,
          isEdited:
            !isEqual(currentItem?.commonData[FieldKeyBasedOnType[currentItem.type]], initialItem?.commonData[FieldKeyBasedOnType[initialItem.type]]) ||
            (currentItem.type === this.dynamicFieldDataTypes.TEMPLATE &&
              !isEqual(
                currentItem?.commonData[FieldKeyBasedOnType[this.dynamicFieldDataTypes.UPLOAD]],
                initialItem?.commonData[FieldKeyBasedOnType[this.dynamicFieldDataTypes.UPLOAD]]
              )) ||
            currentItem?.title !== initialItem?.title,
        };
      }
    }
  }

  /**
   * Adds new section on required position
   * @param index Index ranges (0 - displayList.length -1)
   */
  addNewField(index: number, type: DynamicFieldDataTypes) {
    this.displayList.splice(index, 0, {
      isNew: true,
      isActive: true,
      commonData: { text: '', document: cloneDeep([]), template: cloneDeep([]) },
      clientData: { text: '' },
      firmData: { text: '' },
      type: type,
      title: '',
    });
    this.viewList.splice(index, 0, false);
  }

  /**
   * Validation check on all records
   */
  isSectionsValid() {
    return this.displayList.some(
      (data: SowDynamicFormsValue) =>
        data?.isActive &&
        (!data.title.length ||
          (data.type === this.dynamicFieldDataTypes.QUILL && this.commonService.checkQuillValueIsEmpty(data?.commonData?.text)) ||
          (data.type === this.dynamicFieldDataTypes.UPLOAD && !data.commonData.document[0]?.name) ||
          (data.type === this.dynamicFieldDataTypes.TEMPLATE && !data.commonData.template[0]?.name))
    );
  }

  /**
   * Edited or Added Validation
   */
  isEditedOrAdded() {
    return !this.displayList.some((data: SowDynamicFormsValue) => data?.isNew || data?.isEdited);
  }

  /**
   * On Delete of section
   * @param indexToDelete Index ranges (0 - displayList.length -1)
   */
  onDelete(indexToDelete: number) {
    if (this.displayList[indexToDelete]?.isNew) {
      const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
        disableClose: true,
        width: '450px',
      });
      dialogRef.componentInstance.title = 'Delete';
      dialogRef.componentInstance.message = `Are you sure you want to delete the field ${this.displayList[indexToDelete]?.title || ''}?`;
      dialogRef.componentInstance.acceptanceText = 'Delete';
      dialogRef.afterClosed().subscribe((confirmed) => {
        if (confirmed) {
          this.displayList.splice(indexToDelete, 1);
          this.viewList.splice(indexToDelete, 1);
        }
      });
    } else {
      this.openDeleteDialogConfirmation(indexToDelete);
    }
  }

  /**
   * Delete Confirmation dialog
   * @param indexToDelete Index ranges (0 - displayList.length -1)
   */
  openDeleteDialogConfirmation(indexToDelete: number) {
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      disableClose: true,
      width: '450px',
    });
    dialogRef.componentInstance.title = 'Delete';
    dialogRef.componentInstance.message = `Are you sure you want to delete the field ${this.displayList[indexToDelete]?.title} ?`;
    dialogRef.componentInstance.acceptanceText = 'Delete';
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.onDeleteSectionById.emit(this.displayList[indexToDelete]?._id);
      }
    });
  }

  /**
   * trackByFn for primitive type loop
   */
  trackByFn(index: number) {
    return index;
  }

  /**
   * Saves newly added and edited values
   */
  saveSectionDetails() {
    const values = this.displayList.reduce(
      (acc, item, index) => {
        if (item?.commonData?.document?.[0]?.isNewUpload || item.commonData?.template?.[0]?.isNewUpload) {
          acc.newlyUploaded.push({ ...item, index });
        } else if (item?.isNew) {
          acc.added.push({ ...item, index });
        } else if (item?.isEdited) {
          acc.edited.push({ ...item, index });
        }
        return acc;
      },
      { added: [], edited: [], newlyUploaded: [] }
    );
    this.onSaveDetails.emit(values);
  }

  /**
   * Opens comments
   */
  openComments(items: SowDynamicFormsValue) {
    this.onOpenComments.emit({ fieldName: items.title, fieldId: items._id });
  }

  /**
   * Validates Field
   */
  validateField(items: SowDynamicFormsValue) {
    this.onValidated.emit({ fieldId: items._id, fieldName: items.title });
  }
  /**
   * Show comments
   */
  showAddComments(fieldId: string) {
    const foundItemIndex = this.initialDisplayLists.findIndex((displayItem) => displayItem?._id === fieldId);
    if (foundItemIndex > -1) {
      const initialItem = this.initialDisplayLists[foundItemIndex];
      return !isEmpty(initialItem?.commonData?.[FieldKeyBasedOnType[initialItem.type]]) && this.initialDisplayLists[foundItemIndex]?.title.length;
    }
    return false;
  }

  /**
   * Get previous text diff values
   */
  getPreviousValues(values: SowDynamicFormsValue) {
    if (values?._id) {
      const foundItemIndex = this.initialDisplayLists.findIndex((displayItem) => displayItem?._id === values._id);
      if (foundItemIndex > -1) {
        return this.initialDisplayLists[foundItemIndex]?.commonData?.text !== values?.commonData?.text
          ? this.initialDisplayLists[foundItemIndex]?.commonData?.text
          : this.isInvitedFirm
          ? values?.firmData?.text
          : values?.clientData?.text;
      }
    } else {
      return this.isInvitedFirm ? values?.firmData?.text : values?.clientData?.text;
    }
  }

  /**
   * listens click event in client application
   * @param event mouse click event
   */
  openMenu(event: MouseEvent): void {
    // Calculate the position of the menu based on the mouse click event
    this.menuPosition.x = event.clientX - 95;
  }

  /**
   * Resets menu click event position
   */
  resetPosition() {
    this.menuPosition.x = 0;
  }

  /**
   * Downloads Document
   * @param document has document Key and name
   */
  downloadDocument(document: { key: string }) {
    this.downloadDoc.emit(document);
  }
}
