<div class="settings-container d-flex">
  <div class="settings-list p-3 d-flex flex-column">
    <a routerLink="{{ 'subscription' }}" [class.active]="currentUrl.includes('subscription')">{{ 'Subscription' | i18next }}</a>
    <a routerLink="{{ 'contracts' }}" [class.active]="currentUrl.includes('contracts')">{{ 'TranslateContracts' | i18next }}</a>
    <ng-container *ngIf="!isFirm">
      <a routerLink="{{ 'options' }}" [class.active]="currentUrl.includes('options')">{{ 'Options' | i18next }}</a>
    </ng-container>
    <a routerLink="{{ 'billing-info' }}" [class.active]="currentUrl.includes('billing-info')">{{ 'Billing Info' | i18next }}</a>
    <ng-container *ngIf="!isFirm">
      <ng-container>
        <a routerLink="{{ 'invoice-billing' }}" [class.active]="currentUrl.includes('invoice-billing')">{{ 'Invoices' | i18next }}</a>
      </ng-container>
      <!-- <ng-container>
        <a routerLink="{{ 'off-boarding' }}" [class.active]="currentUrl.includes('off-boarding')">{{ 'Off-boarding' | i18next }}</a>
      </ng-container> -->
    </ng-container>
  </div>

  <div class="flex-one ml-3">
    <router-outlet></router-outlet>
  </div>
</div>
