import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { get, isEmpty } from 'lodash-es';
import { Subscription } from 'rxjs';
import {
  ASSETS,
  AccountDetails,
  AdminService,
  CONSTANTS,
  ClientAccountTypes,
  CompanyService,
  NotificationService,
  StripeBaseUrl,
  UploaderService,
  UserService,
  user,
} from '@conpulse-web/core';
import { UtilityMethodsService } from '../../services';
import { I18NextPipe } from 'angular-i18next';

@Component({
  selector: 'conpulse-web-billing-address',
  templateUrl: './billing-address.component.html',
  styleUrls: ['./billing-address.component.scss'],
})
export class BillingAddressComponent implements OnInit {
  date = new Date();
  routerSubscription: Subscription | null = null;
  companyId: string;
  isSuperAdmin: boolean = false;
  user: user = {} as user;
  isRecordExist: boolean = false;
  accountDetails: AccountDetails = {} as AccountDetails;
  accountTypes = ClientAccountTypes;
  clientCreatedAt: Date;
  isEmpty = isEmpty;
  isLoading: boolean = false;
  isCompanyLoading: boolean = false;
  isClient: boolean = false;
  clientAdminDetails;
  notFoundImage: string;

  constructor(
    private adminService: AdminService,
    private router: Router,
    private userService: UserService,
    private notificationService: NotificationService,
    private utilityService: UtilityMethodsService,
    private readonly uploaderService: UploaderService,
    private companyService: CompanyService,
    private i18Next: I18NextPipe
  ) {
    this.routerSubscription = this.router.events.subscribe((event) => {
      const params = this.utilityService.collectAllRouteParams(this.router);
      if (event instanceof NavigationEnd && event.url) {
        const route = event.url.split('/');
        this.isClient = route[1] === 'dashboard';
        this.adminService.resetAccountDrawer();
        if (
          ![...CONSTANTS.CLIENT_USER_ROLES, ...CONSTANTS.CF_PROFILE_ROLES].includes(this.userService?.currentUserInformation?.role) ||
          !this.userService?.currentUserInformation?.role
        ) {
          this.isSuperAdmin = true;
          this.isClient = route.includes('client');
          this.companyId = params?.id;
        }
      }
    });
    this.notFoundImage = `${this.userService.s3PublicUrl}${ASSETS.NO_DATA_FOUND_IMAGE_PATH}`;
  }

  ngOnInit(): void {
    const params = this.utilityService.collectAllRouteParams(this.router);
    this.user = this.userService.currentUserInformation;
    const route = this.router.url.split('/');
    if (![...CONSTANTS.CLIENT_USER_ROLES, ...CONSTANTS.CF_PROFILE_ROLES].includes(this.user?.role) || !this.userService.currentUserInformation?.role) {
      this.isSuperAdmin = true;
      this.isClient = route.includes('client');
      this.companyId = params?.id;
    }
    this.adminService.reloadAccountSection().subscribe((data) => {
      if (data.reload && data.type === this.accountTypes.AdministrativeInfo) {
        this.getAccountDetails();
      }
    });
    this.getAccountDetails();
  }

  getCompanyById() {
    this.isCompanyLoading = true;
    this.adminService.getCompanyById(this.isSuperAdmin ? this.companyId : this.isClient ? this.user?.companyId?.['_id'] : this.user?.firmId?.['_id']).subscribe(
      (response) => {
        this.clientAdminDetails = response?.data?.userId;
        this.isCompanyLoading = false;
        this.clientCreatedAt = response?.data?.createdAt;
      },
      (error) => {
        this.notificationService.openErrorSnackBar('Unable to fetch company profile');
        this.isCompanyLoading = false;
      }
    );
  }

  getFirmById() {
    this.adminService.getFirmById(this.isSuperAdmin ? this.companyId : this.isClient ? this.user?.companyId?.['_id'] : this.user?.firmId?.['_id']).subscribe(
      (response) => {
        this.clientCreatedAt = response?.data?.createdAt;
        this.isCompanyLoading = false;
      },
      (error) => {
        this.notificationService.openErrorSnackBar('Unable to fetch company profile');
      }
    );
  }

  getAccountDetails() {
    this.isLoading = true;
    this.adminService
      .getAccountDetailsForClients(
        this.isSuperAdmin ? this.companyId : this.isClient ? this.user?.companyId?.['_id'] : this.user?.firmId?.['_id'],
        this.isClient
      )
      .subscribe({
        next: (response) => {
          this.accountDetails = response?.data;
          if (this.accountDetails?.administrativeInfo?.adminContact?._id && !this.isClient) {
            this.clientAdminDetails = JSON.parse(JSON.stringify({ ...this.accountDetails.administrativeInfo.adminContact }));
          }
          this.isClient ? this.getCompanyById() : this.getFirmById();
          this.isLoading = false;
        },
        error: (error) => {
          if (error.code === 404) {
            this.isRecordExist = true;
          } else {
            this.isRecordExist = false;
            this.notificationService.openErrorSnackBar(get(error, 'message', 'Unable to get details'));
          }
          this.isLoading = false;
          this.isClient ? this.getCompanyById() : this.getFirmById();
        },
      });
  }

  openAddEditDrawer(type: string) {
    this.adminService.setAccountDrawerState({
      open: true,
      type: type,
      accountDetails: this.accountDetails,
      isClient: this.isClient,
      clientAdminDetails: this.clientAdminDetails,
      createdAt: this.clientCreatedAt,
    });
  }

  async downloadDocument(documentKey: string) {
    try {
      const response = await this.uploaderService.getFileViewUrl({ folderPath: 'admin/contract', fileKey: documentKey }).toPromise();
      window.open(response?.data?.viewUrl);
    } catch (error) {
      this.notificationService.openErrorSnackBar(`Couldn't download the document`);
    }
  }

  /**
   * The function `createStripeCustomer()` creates a customer in Stripe and handles the success and
   * error cases.
   */
  createStripeCustomer() {
    this.adminService.createCustomerInStripe(this.companyId).subscribe({
      next: (response) => {
        this.notificationService.openSuccessSnackBar(this.i18Next.transform(`Client created in stripe successfully`));
        this.getAccountDetails();
        this.getCompanyById();
      },
      error: (error) => {
        this.notificationService.openErrorSnackBar(get(error, 'message', 'Unable to create customer'));
      },
    });
  }

  /**
   * The function opens a new window to the Stripe portal using the Stripe base URL and the customer
   * ID.
   */
  openStripePortal() {
    //TODO:- The StripeBaseUrl will be replaced from env once dev testing is completed
    window.open(`${StripeBaseUrl}/customers/${this.accountDetails?.administrativeInfo?.['stripeCustomerId']}`, '_blank');
  }

  openCustomerSessionPortal() {
    this.companyService.getStripeCustomerPortalLink().subscribe({
      next: (response) => {
        window.open(response.data, '_blank');
      },
      error: (error) => {
        this.notificationService.openErrorSnackBar(get(error, 'message', 'Unable to redirect to customer portal'));
      },
    });
  }

  /**
   * show tooltip when text truncate
   */
  disableTooltip(event) {
    return event.scrollWidth <= event.clientWidth;
  }
}
