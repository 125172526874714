import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiResponse, LayoutAndTemplateData } from '../interfaces/common-interfaces';
import { NotificationService } from '../notification/notification.service';
import { Category, TemplateType } from '../constants';
import { get } from 'lodash-es';

const templateLibrary = {
  library: 'company/projects/settings/library',
  libraryDuplicate: 'company/projects/settings/library-duplicate',
  createFromExisting: 'company/projects/settings/create-from-existing',
  currency: 'client/currency-conversion',
  syncCurrency: 'client/currency-conversion/sync',
};

@Injectable({
  providedIn: 'root',
})
export class TemplateLibraryService {
  private defaultOptions: object;
  public selectedEmailLanguageId: string = '';
  public selectedQuestionLanguageId: string = '';
  public notificationIntervalTimer: ReturnType<typeof setInterval>;

  private handleError(error: Response): any {
    if (error.status === 0 || error.statusText === 'Unknown Error') {
      this.notificationService.openErrorSnackBar('Check your Network connection. Please try again');
      return throwError({
        message: 'Check your Network connection. Please try again',
      });
    }
    return throwError(
      { ...get(error, 'error'), code: get(error, 'status') } || {
        message: 'Check your Network connection. Please try again',
      }
    );
  }

  constructor(private http: HttpClient, private notificationService: NotificationService) {
    this.defaultOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
      withCredentials: true,
    };
  }

  /* Base HTTP APIs */

  /**
   * Performs HTTP GET Operation
   * @param url URL
   * @returns HTTP Response
   */
  httpGet(url: string): Observable<any> {
    return this.http.get<ApiResponse>(url, this.defaultOptions).pipe(
      map((res) => res),
      catchError((err) => this.handleError(err))
    );
  }

  /**
   * Performs HTTP POST Operation
   * @param url URL
   * @returns HTTP Response
   */
  httpPost(url: string, data?: object): Observable<any> {
    return this.http.post<ApiResponse>(url, data, this.defaultOptions).pipe(
      map((res) => res),
      catchError((err) => this.handleError(err))
    );
  }

  /**
   * Performs HTTP PUT Operation
   * @param url URL
   * @returns HTTP Response
   */
  httpPut(url: string, data?: any): Observable<any> {
    return this.http.put<ApiResponse>(url, data, this.defaultOptions).pipe(
      map((res) => res),
      catchError((err) => this.handleError(err))
    );
  }

  /**
   * Performs HTTP DELETE Operation
   * @param url URL
   * @returns HTTP Response
   */
  httpDelete(url: string): Observable<any> {
    return this.http.delete<ApiResponse>(url, this.defaultOptions).pipe(
      map((res) => res),
      catchError((err) => this.handleError(err))
    );
  }

  /**
   * Gets list of templates library items for given category and type.
   * @param category Library Category (Layout/Template)
   * @param type Type of Template (SoW/RFP/NDA)
   * @param languageId Language Filter (optional)
   * @returns List of templates
   */
  getTemplateAndLayout(category: string, type: string, languageId?: string): Observable<any> {
    const params = new URLSearchParams();
    params.append('category', category);
    params.append('type', type);
    if (languageId) params.append('language', languageId);
    return this.httpGet(`${templateLibrary.library}?${params.toString()}`).pipe(map((res) => res.data));
  }

  /**
   * Gets a library template item by ID
   * @param templateId Unique ID of the template
   * @returns Template Data
   */
  getTemplateAndLayoutById(templateId: string): Observable<any> {
    return this.httpGet(`${templateLibrary.library}/${templateId}`).pipe(map((res) => res.data));
  }

  /**
   * Saves Layout and template library data
   * @returns Add/Update Response
   */
  addOrUpdateLayoutAndTemplate(templateData: LayoutAndTemplateData) {
    return this.httpPost(`${templateLibrary.library}`, templateData);
  }

  /**
   * Deletes a library template by it's unique details.
   * @param id Unique ID of the template
   * @param libraryType Library Category (Layout/Template)
   * @param type Template Type (SoW/RFP/NDA)
   * @returns Delete Response
   */
  deleteTemplateAndLayout(id: string, libraryType: string, type: string): Observable<any> {
    return this.httpDelete(`${templateLibrary.library}/${id}?category=${libraryType}&type=${type}`).pipe(map((res) => res.data));
  }

  /**
   * Duplicates a given template by it's unique details.
   * @param id Unique ID of the template to duplicate
   * @param libraryType Library Category (Layout/Template)
   * @param type Template Type (SoW/RFP/NDA)
   * @returns Duplication Response
   */
  duplicateTemplateAndLayout(id: string, libraryType: string, type: string): Observable<any> {
    return this.httpPut(`${templateLibrary.libraryDuplicate}/${id}?category=${libraryType}&type=${type}`).pipe(map((res) => res.data));
  }

  /**
   * Creates a template from an existing entity
   * @param entityId Unique ID of the existing entity (RFP)
   * @param libraryType Library Category (Layout/Template)
   * @param type Template Type (SoW/RFP/NDA)
   * @returns Creation Response
   */
  createFromExistingEntity(entityId: string, libraryType: Category, type: TemplateType): Observable<any> {
    const params = new URLSearchParams();
    params.append('category', libraryType);
    params.append('type', type);
    return this.httpPost(`${templateLibrary.createFromExisting}/${entityId}?${params.toString()}`).pipe(map((res) => res.data));
  }

  /**
   * Gets Currency table Details
   */
  getCurrencyTableForClient({ page, limit, sortBy, sortOrder, search, isArchivedList }) {
    let url = templateLibrary.currency;
    const params = new URLSearchParams();
    params.append('page', page);
    params.append('limit', limit);
    params.append('sortBy', sortBy);
    params.append('sortOrder', sortOrder);
    params.append('search', search);
    params.append('isArchivedList', isArchivedList);
    return this.httpGet(`${url}?${params.toString()}`);
  }

  /**
   * add currency conversion rates
   */
  addCurrencyConversion(body: { fromCurrency: string; toCurrency: string; conversionValue: number; validity: Date }) {
    const params = new URLSearchParams();
    return this.httpPost(`${templateLibrary.currency}`, body);
  }
  /**
   * Edits currency conversion rates
   */
  editCurrencyConversion(conversionId: string, rate: number, validity: Date) {
    const params = new URLSearchParams();
    params.append('rate', rate.toString());
    params.append('validity', validity.toString());
    return this.httpPut(`${templateLibrary.currency}/${conversionId}?${params.toString()}`);
  }

  /**
   * Delete currency conversion rates
   */
  deleteCurrencyConversion(conversionId: string) {
    return this.httpDelete(`${templateLibrary.currency}/${conversionId}`);
  }

  syncCurrencyConversion() {
    return this.httpPost(`${templateLibrary.syncCurrency}`);
  }
}
