<div>
  <form #form="ngForm" name="form" role="form">
    <div>
      <div>
        <label [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-24': isLoading }">{{ 'Name' | i18next }}</label>
        <mat-form-field class="full-width" floatLabel="never" [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': isLoading }">
          <input
            matInput
            class="h-40"
            placeholder="{{ 'Enter name of the thread' | i18next }}"
            [(ngModel)]="threadName"
            name="chatRoom"
            #chatRoom="ngModel"
            [required]="true"
            (change)="checkValue()"
            (blur)="threadName = trim(threadName)"
          />
          <mat-error *ngIf="chatRoom.hasError('required')">{{ 'Name is required' | i18next }}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
