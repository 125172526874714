import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { of, Observable, from } from 'rxjs';
import { UserService } from '../apis/index';
import { AuthService } from '@auth0/auth0-angular';
import { NotificationService } from '../notification';
import { CONSTANTS } from '../constants';
import { get, includes, isEmpty } from 'lodash-es';

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
    private auth: AuthService,
    private notificationService: NotificationService,
    @Inject(DOCUMENT) private document: any
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    // check whether the user is blocked:
    let error = get(next.queryParams, 'error', '');
    if (error) {
      let errorDescription = get(next.queryParams, 'error_description', '');
      if (errorDescription === 'user is blocked') {
        this.notificationService.openErrorSnackBar('Your Account has been deactivated. Contact your Admin.');
      } else {
        this.notificationService.openErrorSnackBar(errorDescription);
      }
    }

    return from(this.auth.idTokenClaims$).pipe(
      map(async (claims) => {
        if (claims) {
          return this.userService.checkToken(`validateLogin`).subscribe(
            (data) => {
              if (get(data, 'isSuperAdmin', false)) {
                this.document.location.href = this.document.location.origin + '/admin/dashboard';
              } else {
                if (!get(data, 'user.isVerified', false)) {
                  if (get(data, 'user.role', -1) === CONSTANTS.role.clientAdmin && !isEmpty(get(data, 'user.companyId', ''))) {
                    this.router.navigate(['/create-sandbox']);
                    return false;
                  } else {
                    this.router.navigate(['/notverified']);
                    return false;
                  }
                } else if (get(data, 'isInvitedFirm', false) && includes(CONSTANTS.CF_PROFILE_ROLES, get(data, 'user.role', -1))) {
                  this.document.location.href = this.document.location.origin + '/client/invitation-dashboard';
                  this.router.navigate(['/account/step1']);
                } else if (isEmpty(get(data, 'user.firmId', '')) && isEmpty(get(data, 'user.companyId', '')) && get(data, 'isFreeTrial', false)) {
                  this.router.navigate(['/create-sandbox']);
                } else {
                  if (!get(data, 'isFreeTrial', false)) {
                    // User registeration flow have been removed. Client and firms can only be created by super admin
                    // switch (get(data, 'user.firmId.progressCounter', '') || get(data, 'user.companyId.progressCounter', '')) {
                    //   case 1:
                    //     this.router.navigate(['/account/step2']);
                    //     return false;
                    //   case 2:
                    //     this.router.navigate(['/account/step3']);
                    //     return false;
                    //   case 3:
                    //     this.router.navigate(['/account/step4']);
                    //     return false;
                    //   default:
                    //     this.document.location.href = get(data, 'isClient', false)
                    //       ? this.document.location.origin + '/client/dashboard'
                    //       : this.document.location.origin + '/client/invitation-dashboard';
                    //     return false;
                    // }
                    this.document.location.href = get(data, 'isClient', false)
                      ? this.document.location.origin + '/client/dashboard'
                      : this.document.location.origin + '/client/invitation-dashboard';
                    return false;
                  } else {
                    this.document.location.href = get(data, 'isClient', false)
                      ? this.document.location.origin + '/client/dashboard'
                      : this.document.location.origin + '/client/invitation-dashboard ';
                  }
                }
              }
            },
            (error) => {
              return of(true);
            }
          );
        } else {
          const rootUrlSegment = next.url?.[0].path;
          switch (rootUrlSegment) {
            case 'signup':
              setTimeout(() => {
                this.auth.loginWithRedirect({ screen_hint: 'signup' });
              }, 3000);
              break;
            default:
              console.log(rootUrlSegment);
              setTimeout(() => {
                this.auth.loginWithRedirect();
              }, 3000);
              break;
          }
        }
      }),
      catchError(() => {
        return of(true);
      })
    );
  }
}
