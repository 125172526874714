import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { isEmpty } from 'lodash-es';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public firmDetails = new Subject<any>();
  public profileDetails = new Subject<any>();
  public chartData = new Subject<any>();
  public newNotificationData = new Subject<any>();

  constructor() {}

  public pushFirmDetails(data) {
    this.firmDetails.next(data);
  }

  public getFirmDetailsObservable(): Observable<any> {
    return this.firmDetails.asObservable();
  }

  public pushProfileDetails(data) {
    this.profileDetails.next(data);
  }

  public getProfileDetailsObservable(): Observable<any> {
    return this.profileDetails.asObservable();
  }

  public pushDestroy(data) {
    this.chartData.next(data);
  }

  public getDestroy(): Observable<any> {
    return this.chartData.asObservable();
  }

  public pushNotification(data) {
    this.newNotificationData.next(data);
  }

  public getNotificationObservable(): Observable<any> {
    return this.newNotificationData.asObservable();
  }

  /**
   * Get previous text diff values
   */
  getPreviousValues(data, felid: string, initialData, isInvitedFirm: boolean, optional = 'text') {
    return initialData[felid]?.commonData?.[optional] !== data[felid]?.commonData?.[optional]
      ? initialData[felid]?.commonData?.[optional]
      : isInvitedFirm
      ? data[felid]?.firmData?.[optional]
      : data[felid]?.clientData?.[optional];
  }

  /**
   * get the comment count for specific field
   * @param field name
   * @param commentCountList tab field list
   * @returns field comment count
   */
  getCommentCount(field, commentCountList) {
    if (commentCountList.length) {
      const fieldDetails = commentCountList.find((data) => data._id === field);
      return fieldDetails ? fieldDetails.internalCount + fieldDetails.publicCount : 0;
    } else {
      return 0;
    }
  }

  /**
   * get the comment count for specific field in dynamic tab
   * @param field id
   * @param commentCountList tab field list
   * @returns field comment count
   */
  getDynamicCommentCount(field, commentCountList) {
    if (commentCountList?.length && field) {
      const fieldDetails = commentCountList.find((data) => data._id.toString() === field.toString());
      return fieldDetails ? fieldDetails.internalCount + fieldDetails.publicCount : 0;
    } else {
      return 0;
    }
  }

  /**
   * check the quill editor value is empty or not
   * @param value string
   * @returns boolean
   */
  checkQuillValueIsEmpty(value: string) {
    if (value) {
      return isEmpty(value.replace(/<(.|\n)*?>/g, '').trim()) && !value.includes('<img');
    } else {
      return true;
    }
  }

  cloneDeep(data) {
    return JSON.parse(JSON.stringify(data));
  }
}
