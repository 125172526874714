import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewRiskComponent } from './view-risk.component';
import { ConpulseMaterialModule } from '../../material-module';
import { I18NextModule } from 'angular-i18next';
import { ConpulseModule } from '../../conpulse.module';



@NgModule({
  declarations: [ViewRiskComponent],
  imports: [
    CommonModule,
    ConpulseMaterialModule,
    I18NextModule,
    ConpulseModule
  ]
})
export class ViewRiskModule { }
