import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicFormsAndListsComponent } from './dynamic-forms-and-lists.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule } from '@angular/forms';
import { ConpulseMaterialModule } from '../../material-module/conpulse-material.module';
import { ConpulseModule } from '../../conpulse.module';
import { UtilityMethodsService } from '../../services/utility-methods.service';
import { QuillModule } from 'ngx-quill';
import { DynamicFormValidationChipModule } from '../dynamic-form-validation-chip/dynamic-form-validation-chip.module';
import { I18NextModule } from 'angular-i18next';
import { UploadDocsOrLinkModule } from '../upload-docs-or-link/upload-docs-or-link.module';

@NgModule({
  declarations: [DynamicFormsAndListsComponent],
  imports: [
    CommonModule,
    ConpulseModule,
    QuillModule.forRoot(),
    ConpulseMaterialModule,
    DragDropModule,
    FormsModule,
    DynamicFormValidationChipModule,
    I18NextModule,
    UploadDocsOrLinkModule
  ],
  exports: [DynamicFormsAndListsComponent],
})
export class DynamicFormsAndListsModule {
  constructor(private readonly utilityMethod: UtilityMethodsService) {
    this.utilityMethod.prepareQuillModule();
  }
}
