import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[conpulseWebMinValueValidator][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MinValueValidatorDirective, multi: true }],
})
export class MinValueValidatorDirective implements Validator {
  @Input('minValue') minValue: number = 1;

  validate(control: AbstractControl): { [key: string]: any } | null {
    if (control.value !== null && control.value !== undefined) {
      const value = +control.value; // Convert to number
      if (value < this.minValue) {
        return { minValue: true };
      }
    }
    return null;
  }
}
