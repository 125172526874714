import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuillModule } from 'ngx-quill';
import { I18NextModule } from 'angular-i18next';



import { CommonLayoutComponent } from './common-layout.component';
import { ConpulseMaterialModule } from '../../material-module';
import { ConpulseModule } from '../../conpulse.module';


@NgModule({
  declarations: [CommonLayoutComponent],
  imports: [
    CommonModule,
    I18NextModule,
    ConpulseMaterialModule,
    QuillModule.forRoot(),
    ConpulseModule
  ],
  exports: [CommonLayoutComponent],
})
export class CommonLayoutModule { }
